$(function () {
    initNav();
    initEqualHeights();
    initMagnific();
    initMasonry();
    initReferences();
    initEditObject();
    initDates();
    initSelect2();
    initFaq();
});

$(window).load(function () {
    initMaps();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
        initEqualHeights();
    }, 200));
});

function initNav() {
    if (window.innerWidth < 768) {
        $('#btn_nav').unbind().click(function () {
            $('#search_wrapper').slideUp(function () {
                $('#user_wrapper').slideUp(function () {
                    $('#nav_wrapper').slideToggle();
                });
            });
        });

        $('#nav_wrapper ul li').has('ul').each(function () {
            var li = $(this);
            li.children('a').unbind().click(function (e) {
                if (li.children('ul').is(':visible')) {
                    li.children('ul').slideUp();
                } else {
                    e.preventDefault();
                    li.children('ul').slideDown();
                }
            });
        });

        $('#btn_search').unbind().click(function () {
            $('#nav_wrapper').slideUp(function () {
                $('#user_wrapper').slideUp(function () {
                    $('#search_wrapper').slideToggle();
                });
            });
        });

        $('#btn_user').unbind().click(function () {
            $('#nav_wrapper').slideUp(function () {
                $('#search_wrapper').slideUp(function () {
                    $('#user_wrapper').slideToggle();
                });
            });
        });
    } else {
        // RESET
        $('header button').unbind();

        $('#nav_wrapper ul li').has('ul').each(function () {
            var li = $(this);
            li.children('a').unbind();
        });

        $('.subwrapper').removeAttr('style');
        // -- END RESET

        $('#btn_user').unbind().click(function () {
            $('#user_wrapper').slideToggle();
        });
    }
}

function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights .equals').css('height', '');
        if (window.innerWidth >= 720) {
            var height = 0;
            $('.equalHeights .equals').each(function (i, el) {
                if ($(el).outerHeight() > height) {
                    height = $(el).outerHeight();
                }
            });
            $('.equalHeights .equals').outerHeight(height);
        }
    }
}

function initMagnific() {
    $('.magnific').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}

function initMasonry() {
    $('.grid').masonry({
        // set itemSelector so .grid-sizer is not used in layout
        itemSelector: '.grid-item',
        // use element for option
        columnWidth: '.grid-sizer',
        percentPosition: true
    })

    $('#kenmerkenTab').on('shown.bs.tab', function () {
        $('.grid').masonry({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.grid-item',
            // use element for option
            columnWidth: '.grid-sizer',
            percentPosition: true
        })
    });
}

function initMaps() {
    if ($('#maps').length > 0) {

        $.ajax({
            dataType: 'json',
            method: "GET",
            url: $('#maps').data('url'),
            success: function (objects) {
                var map;
                var markers = [];
                var infowindow;
                var bounds = new google.maps.LatLngBounds();

                map = new google.maps.Map(document.getElementById('maps'));

                $.each(objects, function (i, el) {

                    var content = '<div>' +
                        '<h3><a href="/object/' + el.id + '">' + el.adres + '</a></h3>' +
                        '<p>' + el.postcode + ' ' + el.plaats + '</p>' +
                        '<p>Jaar: ' + el.jaar + '<br>' +
                        'Soort: ' + el.soort + '<br>' +
                        'Status: ' + el.status + '</p>' +
                        '</div>';

                    // Add the markers
                    var location = new google.maps.LatLng(el.lat, el.lng);

                    var marker = new google.maps.Marker({
                        map: map,
                        position: location,
                        icon: 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'
                    });

                    markers.push(marker);

                    // Use the results to set the bounds
                    bounds.extend(location);

                    // Add a info window on click: add the address as the info content
                    google.maps.event.addListener(marker, 'click', function () {
                        if (!infowindow) {
                            infowindow = new google.maps.InfoWindow();
                        }
                        infowindow.setContent(content);
                        infowindow.open(map, marker);
                    });
                });

                map.fitBounds(bounds);

                new MarkerClusterer(map, markers, {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
                });

            }
        });
    }
}

function initReferences() {
    if ($('#references').length > 0) {

        var values = {};
        $.each($('#refineform').serializeArray(), function (_, kv) {
            values[kv.name] = kv.value;
        });

        $.each($('#phtform').serializeArray(), function (_, kv) {
            values[kv.name] = kv.value;
        });

        $('#refineform').unbind().on('submit', function (e) {
            e.preventDefault();
            $('#phtform').find('input').val('');
            initReferences();
        });

        $('#phtform').unbind().on('submit', function (e) {
            e.preventDefault();

            $('#myModal .alert').remove();

            $.each($('#phtform').serializeArray(), function (_, kv) {
                values[kv.name] = kv.value;
            });

            if (values.postcode == '' || values.huisnummer == '') {
                $('#phtform').before('<div class="alert alert-danger">Vul een postcode en huisnummer in</div>');
            } else {
                $('#myModal').modal('hide');
                initReferences();
            }
        });

        // Update table rows
        $.ajax({
            dataType: 'html',
            method: "POST",
            url: $('#references').data('url'),
            data: values,
            error: function (error) {
                message = $.parseJSON(error.responseText);
                content = '';
                if (message.postcode || message.huisnummer) {
                    $.each(message, function (i, el) {
                        content = content + el
                    });

                    $('#phtform').before('<div class="alert alert-danger">' + content + '</div>');
                    $('#myModal').modal('show');
                }
            },
            success: function (content) {
                $('#references tbody').html('').append(content);
                initReferenceAdd();
            },
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        });

        updateReferenceBox();
    }
}

function initReferenceAdd() {
    $('.btn-add-reference').each(function (i, el) {
        $(el).on('click', function (e) {
            btn = $(el);
            tr = $(el).parents('tr');

            if (tr.hasClass('success')) {
                $.ajax({
                    dataType: 'json',
                    method: "DELETE",
                    url: $('#references').data('url-delete'),
                    data: tr.data('object'),
                    error: function (error) {
                        console.log(error);
                    },
                    success: function (content) {
                        tr.removeClass('success');
                        btn.html('Toevoegen').removeClass('btn-success').addClass('btn-default');
                        updateReferenceBox();
                    },
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                });
            } else {
                $.ajax({
                    dataType: 'json',
                    method: "POST",
                    url: $('#references').data('url-create'),
                    data: tr.data('object'),
                    error: function (error) {
                        console.log(error);
                    },
                    success: function (content) {
                        tr.addClass('success');
                        btn.html('Toegevoegd').removeClass('btn-default').addClass('btn-success');
                        updateReferenceBox();
                    },
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                });
            }
        });
    });
}

function updateReferenceBox() {
    $.ajax({
        dataType: 'json',
        method: "GET",
        url: $('#references').data('url-count'),
        success: function (count) {
            text = count + ' referentie';
            if (count > 1 || count == 0) {
                text = text + 's';
            }
            $('#references_box h4').html(text + ' geselecteerd');

            if (count == 0) {
                $('#references_box .btn').addClass('disabled');
            } else {
                $('#references_box .btn').removeClass('disabled');
            }
        },
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
    });
}

function initEditObject() {
    if ($('.panel-edit-reference').length > 0) {
        $('.panel-edit-reference .panel-heading .btn').on('click', function (e) {
            e.preventDefault();
            panel = $(this).parents('.panel');
            panel.find('.panel-body').toggleClass('hidden');
        });

        // tab hack for select
        $(window).load(function () {
            $('.panel-edit-reference').find('.tab-pane:not(:first-child)').removeClass('active');
            $('.panel-edit-reference').find('.panel-body').addClass('hidden');
        });

        // Save form
        forms = $('.panel-edit-reference').find('form');

        forms.each(function (i, form) {
            var form = $(form);
            form.find('input, textarea, select').on('change', function (e) {
                e.preventDefault();

                // Update verkoopprijs alert
                verkoopprijs_div = form.find('.alert-verkoopprijs');
                verkoopprijs = parseInt(form.find('input[name=verkoopprijs]').val());
                verkoopprijs_correctie = parseInt(form.find('input[name=gecorrigeerde_verkoopprijs]').val());
                if (verkoopprijs == verkoopprijs_correctie) {
                    verkoopprijs_div.html('Geen verschil');
                } else if (verkoopprijs && verkoopprijs_correctie) {
                    verkoopprijs_div.html('Verschil: <strong>' + (((verkoopprijs_correctie - verkoopprijs) / verkoopprijs * 100).toFixed(1)).replace('.', ',') + '%</strong>');
                }

                // Update bouwjaar alert
                bouwjaar_div = form.find('.alert-bouwjaar');
                bouwjaar_parent = form.find('.alert-bouwjaar').data('parent');
                bouwjaar_reference = parseInt(form.find('input[name=bouwjaar]').val());
                if (bouwjaar_parent == bouwjaar_reference) {
                    bouwjaar_div.html('Geen verschil');
                } else if (bouwjaar_parent && bouwjaar_reference) {
                    bouwjaar_div.html('Verschil: <strong>' + (bouwjaar_reference - bouwjaar_parent) + ' jaar</strong>');
                }

                // Update gebruiksoppervlakte alert
                gebruiksoppervlakte_div = form.find('.alert-gebruiksoppervlakte');
                gebruiksoppervlakte_parent = form.find('.alert-gebruiksoppervlakte').data('parent');
                gebruiksoppervlakte_reference = parseInt(form.find('input[name=gebruiksoppervlakte]').val());
                if (gebruiksoppervlakte_parent == gebruiksoppervlakte_reference) {
                    gebruiksoppervlakte_div.html('Geen verschil');
                } else if (gebruiksoppervlakte_parent && gebruiksoppervlakte_reference) {
                    gebruiksoppervlakte_div.html('Verschil: <strong>' + (((gebruiksoppervlakte_reference - gebruiksoppervlakte_parent) / gebruiksoppervlakte_parent * 100).toFixed(1)).replace('.', ',') + '% (' + (gebruiksoppervlakte_reference - gebruiksoppervlakte_parent) + ' m&sup3;)</strong>');
                }

                // Update inhoud alert
                inhoud_div = form.find('.alert-inhoud');
                inhoud_parent = form.find('.alert-inhoud').data('parent');
                inhoud_reference = parseInt(form.find('input[name=inhoud]').val()) || 0;
                if (inhoud_parent == inhoud_reference) {
                    inhoud_div.html('Geen verschil');
                } else if (inhoud_parent && inhoud_reference) {
                    inhoud_div.html('Verschil: <strong>' + (((inhoud_reference - inhoud_parent) / inhoud_parent * 100).toFixed(1)).replace('.', ',') + '% (' + (inhoud_reference - inhoud_parent) + ' m&sup3;)</strong>');
                }

                // Update perceeloppervlakte alert
                perceeloppervlakte_div = form.find('.alert-perceeloppervlakte');
                perceeloppervlakte_parent = form.find('.alert-perceeloppervlakte').data('parent');
                perceeloppervlakte_reference = parseInt(form.find('input[name=perceeloppervlakte]').val());
                if (perceeloppervlakte_parent == perceeloppervlakte_reference) {
                    perceeloppervlakte_div.html('Geen verschil');
                } else if (perceeloppervlakte_parent && perceeloppervlakte_reference) {
                    perceeloppervlakte_div.html('Verschil: <strong>' + (((perceeloppervlakte_reference - perceeloppervlakte_parent) / perceeloppervlakte_parent * 100).toFixed(1)).replace('.', ',') + '% (' + (perceeloppervlakte_reference - perceeloppervlakte_parent) + ' m&sup2;)</strong>');
                }

                $.ajax({
                    dataType: 'json',
                    method: "PUT",
                    url: form.attr('action'),
                    data: form.serializeArray(),
                    error: function (error) {
                        //console.log(error);
                    },
                    success: function (content) {
                        //console.log(content);
                    },
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                });
            });
        });
    }
}

function initDates() {
    $('.pikaday').pikaday({
        firstDay: 1,
        format: "DD-MM-YYYY"
    });
}

function initSelect2() {
    $('select.select2').select2({
        minimumResultsForSearch: -1,
        placeholder: "Maak een keuze"
    });
}

function initFaq() {
    if ($('#faq').length > 0) {
        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}